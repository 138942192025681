import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faCalendarAlt,
  faMoneyBill,
  faChild,
  faArrowAltCircleLeft,
  faFish,
  faInfo,
} from "@fortawesome/free-solid-svg-icons"

export default function Prestation({ data }) {
  const prestation = data.prestationCard

  return (
    <Layout>
      <SEO title={prestation.title} description={prestation.meta_description} />
      <Container className="pt-5">
        <Row>
          <Col>
            <Link to="/prestations">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              <span className="ml-2">Retour à la liste des prestations.</span>
            </Link>
          </Col>
        </Row>
      </Container>
      {/* <BackgroundImage
        Tag="section"
        fluid={prestation.image.childImageSharp.fluid}
        alt={prestation.title}
        style={{ minHeight: "300px", height: "500px" }}
      >
        <Container className="py-5 ">
          <h1
            className="text-light"
            style={{ textShadow: "#474747 1px 2px 2px" }}
          >
            {prestation.title}
          </h1>
        </Container>
      </BackgroundImage> */}
      <Container className="py-5">
        <h1>{prestation.title}</h1>
        <hr />
        <Row>
          <Col>
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span className="ml-2">Periode : {prestation.periode} </span>
            <br />
            <FontAwesomeIcon icon={faClock} />
            <span className="ml-2">Durée : {prestation.duration}</span>

            {prestation.adulte_price && (
              <div>
                <FontAwesomeIcon icon={faMoneyBill} />
                <span className="ml-2">
                  <b>{prestation.adulte_price} €</b> / personne
                </span>
              </div>
            )}
            {prestation.child_price && (
              <div>
                <FontAwesomeIcon icon={faChild} />
                <span className="ml-2">
                  <b>{prestation.child_price} €</b> / enfant ( -12 ans )
                </span>
              </div>
            )}

            {/* </Col>
          <Col md={6}> */}
            <p className="lead py-3">{prestation.description}</p>

            {prestation.species && (
              <div>
                <FontAwesomeIcon icon={faFish} />
                <span className="ml-2">Espèces recherchées :</span>
                <ul className="ml-2">
                  {prestation.species.map(specie => (
                    <li>{specie}</li>
                  ))}
                </ul>
              </div>
            )}

            {prestation.technical && (
              <div>
                <FontAwesomeIcon icon={faInfo} />
                <span className="ml-2">Techniques utilisées :</span>
                <ul className="ml-2">
                  {prestation.technical.map(tech => (
                    <li>{tech}</li>
                  ))}
                </ul>
              </div>
            )}
          </Col>
          <Col md={6}>
            <Img
              // style={{ height: "200px", width: "300px" }}
              fluid={prestation.image.childImageSharp.fluid}
              alt={prestation.title}
            />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={6}>
            <small>
              Pour l’ensemble des prestations, <b>ne pas oublier</b> : lunettes
              de soleil, crème solaire, casquette, veste ou coupe vent et
              chaussures fermées. <br />
            </small>
            <br />
            {/* </Col>
          <Col md={6}> */}
            <small>
              Le <b>point de rendez-vous</b> se situe à la cabane Cap Pêche
              Loisirs au ponton G du port de Capbreton (avenue Georges
              Pompidou).
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    prestationCard(slug: { eq: $slug }) {
      title
      category
      image {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      periode
      duration
      technical
      species
      adulte_price
      description
      meta_description
    }
  }
`
